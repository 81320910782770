import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './DailyReportStyle.scss';
import { IPlat, getSelecaoPlataformasApi } from '../../services/plataforma';
import { getBhaReportsApi, getCasingReportsApi, getRiserReportsApi, getTimeReportsApi } from '../../services/reports';
import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DadosPlataforma from '../../components/DadosPlataforma/DadosPlataforma';
import PageTitle from '../../components/PageTitle/PageTitle';
import OperacaoTabelas from '../../components/Operacao/Operacao';
import PocoInfo from '../../components/PocoInfo/PocoInfo';
import DepthInfo from '../../components/DepthInfo/DepthInfo';
import EfficiencyTable from '../../components/EfficiencyTable/EfficiencyTable';
import NPTTable from '../../components/NPTTable/NPTTable';
import ReportGroup from '../../components/GraphGroup/GraphGroup';
import { iChartdata, iStatebha, iStatecasing, iStateriser, StateName } from '../../@types/reportgraphs.type';
import ReportTimeGraph from '../../components/GraphGroup/components/ReportTimeGraph/ReportTimeGraph';
import { getReading } from '../../services/reading';
import {setDadosPlataforma, setDadosPlataformaSections, setLoading, setOperacoes, setPocoInfo, setRefresh, setWell} from '../../redux/actions'
import { AppDispatch } from '../../redux/store';
import {connect} from 'react-redux'
import { getSections } from '../../services/section';
import { getPocoInfosApi } from '../../services/dadosPoco';
import { getDepthApi } from '../../services/depth';
import { getOperacoesApi } from '../../services/operacoes';
import { getEfficiencyApi } from '../../services/efficiencytable';
import { iEfficiency, iLessonLearned, iNpt, iPieChart } from '../../@types/efficiency.type';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RzxLogo from '../../assets/images/logo1.png';
import { getWellApi } from '../../services/well';
import LessonLearnedTable from '../../components/LessonLearnedTable/LessonLearnedTable';
import GraphPieGroup from '../../components/GraphPieGroup/GraphPieGroup';
import { useSearchParams } from 'react-router-dom';
import ReportGroupPrint from '../../components/GraphGroupPrint/GraphGroupPrint';
import ReportTimeGraphPrint from '../../components/GraphGroupPrint/components/ReportTimeGraphPrint/ReportTimeGraphPrint';
import GraphPieGroupPrint from '../../components/GraphPieGroupPrint/GraphPieGroupPrint';

const DailyReport: React.FC<{dispatch: AppDispatch, dadospoco: any}> = ({dispatch, dadospoco}) => {
    const [params] = useSearchParams()
    const axis: iChartdata = {x:[], y:[], bar_colors:[], voptimum_target: 0, vstandard_target: 0, vpoor_target: 0, label_list:[], operation_name: []}
    const eventtypes = {
        slip_to_slip: axis,
        pipe_move: axis,
        gross_speed: axis
        }
      const initial_bha={
        open_hole: eventtypes,
        cased: eventtypes,
        drilling: eventtypes,
        }
      const initial_casing={
        settlement: eventtypes,
        casing: eventtypes,
        belowshoedepth: eventtypes
        }
      const initial_riser={
        ascend: eventtypes,
        descend: eventtypes,
        }
        const initial_time=axis
    const whitecolor  = "#FFF"
    const [databha, setDatabha] = React.useState<iStatebha>(initial_bha)
    const [datacasing, setDatacasing] = React.useState<iStatecasing>(initial_casing)
    const [datariser, setDatariser] = React.useState<iStateriser>(initial_riser)
    const [datatime, setDatatime] = React.useState<iChartdata>(initial_time)
    const [startdate, setStartdate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
    const [enddate, setEnddate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
    const [selecteddate, setSelectedDate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
    const [plataformalist, setPlataformalist] = React.useState<IPlat[]>([])
    const [selectedplataforma, setSelectedplataforma] = React.useState<string|null>('')
    const [earliestdate, setEarliestdate] = React.useState<number>(0)
    const [latestdate, setLatestdate] = React.useState<number>(0)
    const [efficiency, setEfficiencydata] = React.useState<iEfficiency|null>(null)
    const [npt, setNptdata] = React.useState<iNpt[]>([])
    const [lessonlearned, setLessonlearneddata] = React.useState<iLessonLearned[]>([])
    const initdata_piechart={values:[], labels:[], text:[]}
    const [nptGraphdata, setNptGraphdata] = React.useState<iPieChart>(initdata_piechart)
    const [lessonsGraphdata, setLessonsGraphdata] = React.useState<iPieChart>(initdata_piechart)
    const getSelecao = () => {
        getSelecaoPlataformasApi().then(response => {
          setPlataformalist(response)
        })};
    const getReports = () => {
        let starttime = moment(startdate).format('YYYY-MM-DD HH:mm')
        let endtime = moment(enddate).format('YYYY-MM-DD HH:mm')
        setDatabha(initial_bha)
        setDatacasing(initial_casing)
        setDatariser(initial_riser)
        setDatatime(initial_time)
        getBhaReportsApi(selectedplataforma, starttime, endtime).then(res=>{
            setDatabha(res.data.bha)
        })
        getCasingReportsApi(selectedplataforma, starttime, endtime).then(res=>{
            setDatacasing(res.data.casing)
        })
        getRiserReportsApi(selectedplataforma, starttime, endtime).then(res=>{
            setDatariser(res.data.riser)
        })
        getTimeReportsApi(selectedplataforma, starttime, endtime).then(res=>{
            setDatatime(res.data.time)
        })
        }

    const setStartEndDate = (value: string ) => {
        setStartdate(moment(value).subtract(1, 'days').format('YYYY-MM-DD HH:mm'))
        setEnddate(moment(value).format('YYYY-MM-DD HH:mm'))
        setSelectedDate(moment(value).format('YYYY-MM-DDTHH:mm'))
    }
    const getDadosPlataforma = ()=>{
        dispatch(setLoading("dadosplataforma", true))
        getReading(selectedplataforma).then((res:any)=>{
            dispatch(setLoading("dadosplataforma", false))  
            dispatch(setDadosPlataforma({...res}))
          }).catch(err=>{
            // TODO colocar logica se der erro no axios
          })
    }
    const getPlataformaSections = () => {
        getSections(selectedplataforma).then(res => {
          dispatch(setDadosPlataformaSections(res))
        })
    }
    const getPocoInfo = () => {
        getPocoInfosApi(selectedplataforma).then(res => {
          dispatch(setPocoInfo(res.pocoinfos))
        })
    }
    const getDepth = () =>  {
        let starttime = moment(startdate).format('YYYY-MM-DD HH:mm')
        let endtime = moment(enddate).format('YYYY-MM-DD HH:mm')
        getDepthApi(selectedplataforma, starttime, endtime).then(res => {
            setEarliestdate(res['earliest_depths']['rounded_depth'])
            setLatestdate(res['latest_depths']['rounded_depth'])
        })
    }
    const getOperacoes = () => {
        dispatch(setLoading("operacao", true))
        getOperacoesApi(selectedplataforma).then(res => {
          dispatch(setLoading("operacao", false))
          dispatch(setOperacoes(res))
        })
      }
    const getEfficiency = () => {
        let starttime = moment(startdate).format('YYYY-MM-DD HH:mm')
        let endtime = moment(enddate).format('YYYY-MM-DD HH:mm')
        setEfficiencydata(null)
        setNptdata([])
        setLessonlearneddata([])
        getEfficiencyApi(selectedplataforma, starttime, endtime).then(res => {
            setEfficiencydata(res['efficiency'])
            setNptdata(res['npt']['npt_list'])
            setLessonlearneddata(res['lessonlearned']['lesson_learned_list'])
        setNptGraphdata({
            values:res['npt']['duration_values_list'],
            labels:res['npt']['label_list'],
            text:res['npt']['duration_label_list'],
        })
        setLessonsGraphdata({
            values:res['lessonlearned']['duration_values_list'],
            labels:res['lessonlearned']['label_list'],
            text:res['lessonlearned']['duration_label_list'],
        })
        }) 
    }
    const formatDatetostring = (startdate: string, enddate: string) => {
        let starttime = moment(startdate).format('DD-MMM HH')
        let endtime = moment(enddate).format('DD-MMM HH')
        return `(${starttime}h to ${endtime}h)`
    }
    const getWell = () => {
        getWellApi(selectedplataforma).then(res => {
          dispatch(setWell(res))
        })
      }
    React.useEffect(() => {
        getSelecao()
        const query = new URLSearchParams(window.location.search);
        setSelectedplataforma(params.get('plataforma'))
        setStartEndDate(params.get('startdate')!)
        },[])
    React.useEffect(() => {
        dispatch(setRefresh())
        if(selectedplataforma){
        getReports()
        getEfficiency()
        getDepth()
        getOperacoes()
        getPocoInfo()
        getPlataformaSections()
        getDadosPlataforma()
        getWell()
        }},[selectedplataforma, startdate])
    
    return (
        <div className="App dailyreport vh-100">
            <div className='Navbar'>
            <NavBar />
            </div>
            <div className="Breadcrumble d-flex text-white pl-3 py-1">
                XPD {'>'} {selectedplataforma} {'>'} daily report
            </div>
            <div className="row-title ml-3 mr-1 d-flex bg-cinza">
                <div className="row-title-column"><img src={RzxLogo} alt="Rzx" className='Logo'/></div>
                <div className="row-title-column" style={{textAlign: 'center'}}>
                    <div style={{width:'100%', height:'50px'}}>
                        <PageTitle title={`Daily Report ${formatDatetostring(startdate, enddate)}`} />
                    </div>
                </div>
                <div className="row-title-column">
                <div className='Select d-flex w-100 p-2 flex-row-reverse text-white'>
                    <TextField
                        id="date-daily"
                        label="Date"
                        type="datetime-local"
                        value={selecteddate}
                        onChange={(e)=>{setStartEndDate(e.target.value)}}
                        sx={{
                            svg: { color: whitecolor },
                            input: { color: whitecolor },
                            label: { color: whitecolor },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                        <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-label" sx={{color:'#FFF'}}>Rig</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedplataforma}
                            label="Rig"
                            onChange={(e)=>{setSelectedplataforma(e.target.value)}}
                            sx={{
                                svg: { color: whitecolor }
                            }}
                            style={{color: whitecolor}}
                            >
                        {plataformalist.map(plataforma => (
                            <MenuItem value={plataforma.db_name} key={plataforma.name}>{plataforma.name}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </div>
                </div>
            </div>

            <div className='Page1 d-flex flex-column'>
                <div className='flex-1 d-flex' style={{width:'100%'}}>
                    <DadosPlataforma rodape={false}/>
                    <div className='d-flex flex-column flex-1'style={{width:'70%', height:'100%'}}>
                        <div style={{width:'100%'}}>
                            <div className='pocoinfos align-stretch mr-1 d-flex bg-cinza'>
                                <div className='my-1 flex-1 pocoinfos-row' style={{height:'100%'}}>
                                    <div>
                                        <PocoInfo pocoinfos={dadospoco?.pocoinfos}/>
                                    </div>
                                    <div className='pr-2'>   
                                        <div className="row poco-info text-white">
                                            <label htmlFor="info-1" className="text-right"><PinDropIcon fontSize="large"/></label>
                                            <div className="d-flex flex-column pin-infos">
                                            <span className="flex-1">{dadospoco?.pocoinfos?.country}</span>
                                            <span className="flex-1">{dadospoco?.pocoinfos?.latitude}</span>
                                            <span className="flex-1">{dadospoco?.pocoinfos?.longitude}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-1 border flex-1'>
                                    <DepthInfo hour={`${moment(startdate).format('DD-MMM HH')}h`} rounded_depth={earliestdate}/>
                                </div>
                                <div className='my-1 flex-1'>
                                    <DepthInfo hour={`${moment(enddate).format('DD-MMM HH')}h`} rounded_depth={latestdate}/>
                                </div>
                            </div>
                        </div>
                        <div className='Operacoes' style={{width:'100%', height:'80%'}}>
                            <OperacaoTabelas/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Efficiency d-flex flex-column'style={{width:'100%'}}>
                <div style={{width:'100%', height:'50px'}}>
                    <PageTitle title='Main Operational Activities 24H'/>
                </div>
                <div className='flex-1 ml-3 mr-1 d-flex bg-cinza'>
                    <EfficiencyTable dados={efficiency}/>
                </div>
                <span className='text-cinza-escuro ml-3' style={{textAlign:'left'}}>
                    *Efficiency will only be calculated for the events finished between report interval.
                </span>
            </div>
            <div className='NPTLessonPieGraph d-flex flex-column'>
                <GraphPieGroup
                    failures={nptGraphdata}
                    lessons={lessonsGraphdata}
                />
                <GraphPieGroupPrint
                    failures={nptGraphdata}
                    lessons={lessonsGraphdata}
                />
            </div>
            
            
            <div className='Reports d-flex flex-column'>
            <ReportGroup
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Open Hole 24H'}
                state={StateName.open_hole}
            />
            <ReportGroupPrint
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Open Hole 24H'}
                state={StateName.open_hole}
            />
            {/* Gráficos BHA - Cased */}
            <ReportGroup
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Cased Hole 24H'}
                state={StateName.cased}
            />
            <ReportGroupPrint
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Cased Hole 24H'}
                state={StateName.cased}
            />
            {/* Gráficos BHA - Drilling */}
            <ReportGroup
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Drilling 24H'}
                state={StateName.drilling}
            /> 
            <ReportGroupPrint
                exibe_grafico_avg
                data={databha}
                startdate={startdate}
                enddate={enddate}
                title={'BHA - Drilling 24H'}
                state={StateName.drilling}
            />  
            {/* Gráficos Casing - Settlement */}
            <ReportGroup
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing- Settlement String 24H'}
                state={StateName.settlement}
            />
            <ReportGroupPrint
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing- Settlement String 24H'}
                state={StateName.settlement}
            />
            {/* Gráficos Casing - Casing */}
            <ReportGroup 
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing 24H'}
                state={StateName.casing}
            />
            <ReportGroupPrint 
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing 24H'}
                state={StateName.casing}
            />
            {/* Gráficos Casing - BelowShoeDepth(Open Hole) */}
            <ReportGroup 
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing - Open Hole 24H'}
                state={StateName.belowshoedepth}
            />
            <ReportGroupPrint
                exibe_grafico_avg
                data={datacasing}
                startdate={startdate}
                enddate={enddate}
                title={'Casing - Open Hole 24H'}
                state={StateName.belowshoedepth}
            />
            {/* Gráficos Riser - Ascend */}
            <ReportGroup 
                exibe_grafico_avg
                data={datariser}
                startdate={startdate}
                enddate={enddate}
                title={'Riser - Ascent 24H'}
                state={StateName.ascend}
            />
            <ReportGroupPrint 
                exibe_grafico_avg
                data={datariser}
                startdate={startdate}
                enddate={enddate}
                title={'Riser - Ascent 24H'}
                state={StateName.ascend}
            />
            {/* Gráficos Riser - Descend */}
            <ReportGroup 
                exibe_grafico_avg
                data={datariser}
                startdate={startdate}
                enddate={enddate}
                title={'Riser - Descend 24H'}
                state={StateName.descend}
            />
            <ReportGroupPrint 
                exibe_grafico_avg
                data={datariser}
                startdate={startdate}
                enddate={enddate}
                title={'Riser - Descend 24H'}
                state={StateName.descend}
            />
                {/* Gráficos Time*/}
            <ReportTimeGraph
                exibe_grafico_avg
                data={datatime}
                startdate={startdate}
                enddate={enddate}
                title={'Time Operations 24H'}
            />
            <ReportTimeGraphPrint
                exibe_grafico_avg
                data={datatime}
                startdate={startdate}
                enddate={enddate}
                title={'Time Operations 24H'}
            />
            </div>
            <div className='Lessonlearned d-flex flex-column'style={{width:'100%'}}>
                <div style={{width:'100%', height:'50px'}}>
                    <PageTitle title='Sub Operational Activities 24H'/>
                </div>
                <div className='flex-1 ml-3 mr-1 d-flex bg-cinza'>
                    <LessonLearnedTable dados={lessonlearned}/>
                </div>
            </div>
            <div className='Npt d-flex flex-column'style={{width:'100%'}}>
                <div style={{width:'100%', height:'50px'}}>
                    <PageTitle title='Reported Failure/Delay(NPT) 24H'/>
                </div>
                <div className='flex-1 ml-3 mr-1 d-flex bg-cinza'>
                    <NPTTable dados={npt}/>
                </div>
            </div>
        </div>
    )
}

export default connect((state: {dadospoco: any[]})=>({
    dadospoco: state.dadospoco,
})
)(DailyReport);