import { iMailing } from '../@types/plataforma.type';
import {api} from './api';

export const getMailingApi = (plataforma: string | null, page = 1, search = '') => {
    return api.get('accounts/mailing/', {params:{plataforma, page, search}}).then(res => res.data);
  }
  
  export const createMailingApi = (mailing: iMailing) => {
    return api.post('accounts/mailing/', mailing);
  }
  
  export const updateMailingApi = (mailing: iMailing, plataforma: string | null) => {
    return api.put(`accounts/mailing/${mailing.id}/edit_mailing/`, mailing, {params:{plataforma}});
  }
  
  export const deleteMailingApi = (mailing: iMailing, plataforma: string | null | undefined) => {
    return api.delete(`accounts/mailing/${mailing.id}/`, {params:{plataforma}});
  }
  
  export const getMailingApiId = (mailing: iMailing) => {
    return api.get(`accounts/mailing/${mailing.id}/`);
  }