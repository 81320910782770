import React from 'react';
import { Box } from '@mui/material';
import RzxLogo from '../../assets/images/logo1.png';

const BoxLogo: React.FC = () => {
    return (
        <Box style={{backgroundColor:'#000', borderRadius: 15, padding: '.5rem', marginTop: 15, 
          marginBottom: 10}}>
          <img src={RzxLogo} alt="Logo" />
        </Box>
    )
}

export default BoxLogo;