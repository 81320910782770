import React from "react";
import Plot from "react-plotly.js";
import './ReportPlannedRealGraphStyle.scss';

interface iReportPlannedRealGraph {
    data: any,
    bgclassname: String,
    labelname: React.ReactNode,
}
const ReportPlannedRealGraph: React.FC<iReportPlannedRealGraph>=({
        data,
        bgclassname,
        labelname,
    })=>{
      const create_custom_label=(days:number[], planned: number[], real: number[])=>{
        const array_final=days.map((value: number, indice: number)=>[value, planned[indice], real[indice]]
        )
        return array_final
      }
    return(
        <div className="Graficos flex-78 mx-3 d-flex">
              
        <div className={"SideLabel "+bgclassname}>
          {labelname}
        </div>
          <div className="Grafico flex-1 flex-graph-print d-flex flex-column">
            
          {/* PlaneneVsReal */}
          <Plot
              divId={'id_plannedReal'}
              data={[
                {
                  type: 'scatter',
                  x: data['days'],
                  y: data['planned'],
                  mode: 'lines+markers',
                  name: "planned",
                  line: {
                    color: 'rgb(55, 128, 191)',
                    width: 3
                  },
                  customdata: create_custom_label(data['days'], data['planned'], data['real']),
                  hovertemplate:`<b>Day</b>: %{customdata[0]}` +
                                `<br><b>Planned</b>: %{customdata[1]}` +
                                `<br><b>Real</b>: %{customdata[2]}<br>`
                 },
                {
                 type: 'scatter',
                 x: data['days'],
                 y: data['real'],
                 mode: 'lines+markers',
                 name: "real",
                 line: {
                  color: 'rgb(231, 84, 84)',
                  width: 2
                },
                customdata: create_custom_label(data['days'], data['planned'], data['real']),
                  hovertemplate:`<b>Day</b>: %{customdata[0]}` +
                                `<br><b>Planned</b>: %{customdata[1]}` +
                                `<br><b>Real</b>: %{customdata[2]}<br>`
                },
              ]}
              config={{
                displaylogo: false,
                responsive: true
              }}
              layout={{
                legend: {font:{size:15}},
                barmode: 'relative',
                showlegend: true,
                height: 250,
                bargap: 0,
                bargroupgap: 0,
                colorway: ['#1f77b4'],
                margin: {
                  t: 50,
                  b: 50,
                  l: 40,
                  r: 20},
                  xaxis:
                  {
                    title: {
                      text: 'Days',
                      font: {
                        size: 18,
                      },
                    },
                    autorange: true,
                    // range: [data['days'][0], data['days'][data['days'].length-1]],
                    zeroline: false,
                    tickfont: {
                      size: 18,
                    },
                    // side: "top",
                  },
                  yaxis:
                  {
                    showgrid: true,
                    zeroline: false,
                    autorange: "reversed",
                    tickfont: {
                      size: 16,
                    },
                    
                  },
              } }
            />
          </div>
      </div>
      
    )
}

export default ReportPlannedRealGraph