import { PlotlyData } from "./plotlydata.type"

export enum StateName{
  open_hole='open_hole',
  cased='cased', 
  drilling='drilling',
  settlement='settlement',
  casing= 'casing',
  belowshoedepth= 'belowshoedepth',
  ascend= 'ascend',
  descend= 'descend',
}
export interface iReportGroup{
    data: any,
    startdate: string,
    enddate: string,
    title: string,
    state?: StateName,
    exibe_grafico_avg?: boolean
}
export interface iChartdata{
    x: number[],
    y: string[],
    bar_colors: string[],
    voptimum_target: number,
    vpoor_target: number,
    vstandard_target: number,
    label_list:string[],
    operation_name: string[]
  
  }
export interface iEventtype{
    slip_to_slip: iChartdata,
    pipe_move: iChartdata,
    gross_speed: iChartdata
  }
export interface iStatebha{
    open_hole: iEventtype,
    cased: iEventtype,
    drilling: iEventtype,
  }
export interface iStatecasing{
    settlement: iEventtype,
    casing: iEventtype,
    belowshoedepth: iEventtype
  }
export interface iStateriser{
    ascend: iEventtype,
    descend: iEventtype,
  }
export interface iPlannedReal{
  days: number[],
  planned: number[],
  real: number[],
}