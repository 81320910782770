import { iConsistency } from "../@types/consistency.type"
import { iDadosPoco } from "../@types/dadospoco.type"
import { iOperacao } from "../@types/operacao.type"
import { iWell } from "../@types/plataforma.type"
import { iPocoInfos } from "../@types/pocoinfo.type"

export const setAlarms = (alarms: any[])=>{
  return {
    type: 'SET_ALARMS',
    alarms
  }
}

export const setMeta = (meta: any) => {
  return {
    type: 'SET_META',
    meta
  }
}

export const setGraficosData = (graficos: any) => {
  return {
    type: 'SET_GRAFICOS_DATA',
    graficos
  }
}

export const setDadosPlataforma = (plataforma: any) => {
  return {
    type: 'SET_DADOS_PLATAFORMA',
    plataforma
  }
}

export const setDadosPlataformaSections = (sections: any) => {
  return {
    type: 'SET_DADOS_PLATAFORMA_SECTIONS',
    sections
  }
}

export const setTopdriveData = (topdrive: any) => {
  return {
    type: 'SET_TOPDRIVE_DATA',
    topdrive
  }
}

export const setDadosPoco = (dadospoco: iDadosPoco) => {
  return {
    type: 'SET_DADOS_POCO',
    dadospoco
  }
}

export const setCurrentOperation = (currentOperation: any) => {
  return {
    type: 'SET_CURRENT_OPERATION',
    currentOperation
  }
}

export const setPocoInfo = (pocoinfos: iPocoInfos) => {
  return {
    type: 'SET_POCO_INFO',
    pocoinfos
  }
}

export const setConsistency = (consistency: iConsistency) => {
  return {
    type: 'SET_CONSISTENCY',
    consistency
  }
}

export const setCurrentCrew = (currentcrew: any) => {
  return {
    type: 'SET_CURRENT_CREW',
    currentcrew
  }
}

export const setOperacoes = (operacoes: iOperacao) => {
  return {
    type: 'SET_OPERACOES',
    operacoes
  }
}

export const setWell = (well: iWell) => {
  return {
    type: 'SET_WELL',
    well
  }
}

export const setRefresh = () => {
  return {
    type: 'SET_REFRESH'
  }
}

export const setLoading = (request_name: string, request_value: boolean) => {
  return {
    type: 'SET_LOADING',
    request_name,
    request_value,
  }
}