import React from "react";
import PinDropIcon from '@mui/icons-material/PinDrop';
import './DadosPocoStyles.scss';
import { PieChart, Pie, Cell } from "recharts";
import fotoPerfil from '../../assets/images/profile-icon-24.jpg';
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";
import PocoInfo from "../PocoInfo/PocoInfo";


interface iChartData{
  name: string;
  value: number;
}


const DadosPoco: React.FC<{dadospoco: any, loading: boolean}> = ({dadospoco, loading}: any) => {
  const GRADIENT_COLORS = [
    { start: "#f7650a", end: "#06c41c" },
    // {start: "#06c41c", end: "#0a65f7"},
    { start: "#e9edf7", end: "#bec0c4" },
  ]
  const GRADIENTE_COLORS_DANGER = [
    {start: '#f7650a', end: '#06c41c'},
    { start: "#e9edf7", end: "#bec0c4" },
  ]
  const [overallChartData, setOverallChartData] = React.useState<iChartData[]>([]);
  const [currentChartData, setCurrentChartData] = React.useState<iChartData[]>([]);
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any, tipo:string) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      index === 0 ? 
      <>
      <text x={500} y={50} fill="white" textAnchor={'middle'} dominantBaseline="central">
        <tspan x={cx} y={50} textAnchor={'middle'} 
          dominantBaseline="central"
          className="font-bold"  
        >{`${(percent * 100).toFixed(0)}%`} </tspan>
        <tspan x={cx} y={90} textAnchor={'middle'} dominantBaseline="central">{tipo}</tspan>
      </text>
      </> : <></>
    );
  };

  React.useEffect(() => {
    const overallData = [
        {name: 'value', value: dadospoco.consistency.overall*100}, 
        {name: 'value', value: 100-dadospoco.consistency.overall*100}
      ];
    setOverallChartData(overallData);
    const currentData = [
          {name: 'value', value: dadospoco.consistency.current*100}, 
          {name: 'value', value: 100-dadospoco.consistency.current*100}
        ];
    setCurrentChartData(currentData);
  }, [dadospoco])
  
  return (
    <section className='infos-poco flex-25 bg-cinza mb-3 py-1 d-flex text-white'>
      <div className="d-flex flex-1">
        <div className="flex-1 d-flex p-3">
          <PocoInfo pocoinfos={dadospoco?.pocoinfos}/>
          <div className="flex-1 font-small info-operacao">
            <div className="row poco-info">
              <label htmlFor="info-1" className="text-right"><PinDropIcon fontSize="large"/></label>
              <div className="d-flex flex-column pin-infos">
                <span className="flex-1">{dadospoco?.pocoinfos?.country}</span>
                <span className="flex-1">{dadospoco?.pocoinfos?.latitude}</span>
                <span className="flex-1">{dadospoco?.pocoinfos?.longitude}</span>
              </div>
            </div>
          </div>
        </div>
        {/* Graficos  */}
        <div className="consistency d-flex flex-column p-relative">
        {loading?
        <div style={{position: "absolute", right: 5, top: 5}}><CircularProgress size="15px" /></div>: <></>}
          <span className="font-bold">CONSISTENCY</span>
          <div className="flex-justify-around graficos d-flex">
            <PieChart width={150} height={100}>
                <defs>
                  {overallChartData.map((entry, index)=>(
                    <linearGradient id={`myGradient${index}`} key={index}>
                      <stop
                        offset="50%"
                        stopColor={GRADIENT_COLORS[index % GRADIENT_COLORS.length].start}
                      />
                      <stop
                        offset="100%"
                        stopColor={GRADIENT_COLORS[index % GRADIENT_COLORS.length].end}
                      />
                    </linearGradient>
                  ))}
                </defs>
              <Pie
                data={overallChartData}
                startAngle={210}
                endAngle={-30}
                innerRadius={35}
                outerRadius={40}
                paddingAngle={1}
                isAnimationActive={false}
                label={(e)=>renderCustomizedLabel(e, 'Operation Overall')}
                dataKey="value"
              >
                {overallChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`}/>
                ))}
              </Pie>
            </PieChart>
            <PieChart width={150} height={100}>
              <defs>
                {currentChartData.map((entry, index)=>(
                  <linearGradient id={`myGradient2${index}`} key={index}>
                    <stop
                      offset="50%"
                      stopColor={GRADIENTE_COLORS_DANGER[index % GRADIENTE_COLORS_DANGER.length].start}
                    />
                    <stop
                      offset="100%"
                      stopColor={GRADIENTE_COLORS_DANGER[index % GRADIENTE_COLORS_DANGER.length].end}
                    />
                  </linearGradient>
                ))}
              </defs>
              <Pie
                data={currentChartData}
                startAngle={210}
                endAngle={-30}
                innerRadius={35}
                outerRadius={40}
                paddingAngle={1}
                isAnimationActive={false}
                label={(e)=>renderCustomizedLabel(e, dadospoco?.consistency?.label)}
                dataKey="value"
              >
                {currentChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#myGradient2${index})`} />
                ))}
              </Pie>
            </PieChart>
          </div>
          
        </div>
      </div>
      <div className="flex-1 d-flex flex-column">
        <span className="flex-20 font-bold">CURRENT CREW</span>
        <div className="flex-1 d-flex flex-wrap px-5">
          {dadospoco?.currentcrew?.length > 0 ? 
            dadospoco?.currentcrew?.map((crew: any, index: any)=>(
                <div className="pessoa d-flex font-small" key={index}>
                  <div className="foto" 
                    style={{backgroundImage: `url(${fotoPerfil})`}}>
                  </div>
                  <div className="dados-pessoa d-flex flex-column p-1">
                    <span className="font-bold">{crew.crewmember}</span>
                    <span>{crew.function}</span>
                  </div>
                </div>
            )) : 
            <div className="flex-center flex-1 text-center flex-column p-1">Crew not found</div>
          }
        </div>
            
      </div>
    </section>
  );
};

export default connect((state: {dadospoco: any[], loading: {consistency: boolean}})=> (
  {
  dadospoco: state.dadospoco,
  loading: state.loading.consistency
}))(DadosPoco);