import React from "react";
import './PageTitleStyle.scss';

const PageTitle: React.FC<{title: string}>=({title})=>{
    return(
        <div className="pagetitle text-cinza-escuro text-center">
            <span>{title}</span>
        </div>

    )
}

export default PageTitle