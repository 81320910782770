import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface iDeleteDialog{
    openDeleteDialog: boolean;
    headerText?: string;
    bodytext?: string;
    onCancel: () => void;
    onDelete: (deleted: any) => void;
}
const DeleteDialog: React.FC<iDeleteDialog> = ({
  openDeleteDialog, headerText = 'Delete', bodytext = 'Confirm delete?',
  onCancel, onDelete
}) => {
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={()=>{}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        {headerText}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description">
          {bodytext}
      </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onDelete} autoFocus color="error">
            Confirm
        </Button>
      </DialogActions>
  </Dialog>
  )
}

export default DeleteDialog;