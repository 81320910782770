import React from "react";
import './AlarmeStyle.scss';
import NotificationsIcon from '@mui/icons-material/Notifications'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import {connect} from 'react-redux';
import { CircularProgress } from "@mui/material";
interface iAlarm{
  id: number,
  titulo: string,
  label: string,
  status: string
}

const Alarme: React.FC<{alarms: iAlarm[], loading: boolean}> = ({alarms=[], loading}) => {

  const getStatusColor = (status: string) => {
    switch(status){
      case 'success': return <CheckCircleIcon style={{fontSize:14, color:"green"}}/>;
      case 'warning': return <CancelIcon style={{fontSize:14, color:"#f29d38"}}/>;
      default: return <CircleOutlinedIcon style={{fontSize:14, color:"#ffffff42"}}/>
    }
  }
  return (
    <div className="infos-alarme tableas-grafico-direita d-flex flex-column bg-cinza flex-20 ml-1 p-1 p-relative">
      {loading?
      <div style={{position: "absolute", right: 5, top: 5}}><CircularProgress size="15px" /></div>: <></>}
      <table className="w-100 d-flex flex-column">        
        <thead>
            <tr className="w-100">
              <th className ='thead-texto-branco thead-font text-white' colSpan={4}>
                <NotificationsIcon style={{fontSize:15}}/> CURRENT OPERATION ALARMS/ALERTS</th>
            </tr>
        </thead>
        <tbody className={`tbody-font text-white  ${!alarms.length ? 'no-alarms':''}`}>
        {!alarms.length ? 
          <tr>
              <td className='text-center thead-font text-white' colSpan={4}>
                No alarms for this operation
              </td>
          </tr> : 
          alarms.map((item, index) => (
            <tr key={index} className="text-white">
              <td><b>[{index+1}]</b></td>
              <td className="text-left" width={80}><b>{item.titulo}</b></td>
              <td className="label" width={200}>{item.label}</td>
              <td className="text-right mr-3">{getStatusColor(item.status)}</td>
            </tr>
          ))}
        </tbody>       
      </table>
      <div className='legenda d-flex text-white'>
        <CheckCircleIcon style={{fontSize:14, color:"green"}}/>
        <span>driller acknowledged</span>
        <CancelIcon style={{fontSize:14, color:"#f29d38"}}/>
        <span>triggered</span>
        <CircleOutlinedIcon style={{fontSize:14, color:"#ffffff42"}}/>
        <span>scheduled</span>
      </div>
    </div>
  );
};

export default connect((state: {alarms: iAlarm[], loading: {alarme: boolean}},) => ({
  alarms: state.alarms,
  loading: state.loading.alarme
}))(Alarme);