import React from 'react';

interface iGraricosInfo {
  goal: string | number;
  average: string | number;
  current: string | number;
}
const GraficoInfos: React.FC<iGraricosInfo> = ({goal, average, current}) => {
  return (
    <div className="d-flex flex-column text-left p-1">
      <div className="info flex-1 d-flex">
        <span className='font-bold flex-1'>Goal: </span>
        <span className='flex-1 text-center'>{goal}</span>
      </div>
      <div className="info flex-1 d-flex">
        <span className='font-bold flex-1'>Average: </span>
        <span className='flex-1 text-center'>{average}</span>
      </div>
      <div className="info flex-1 d-flex">
        <span className='font-bold flex-1'>Current: </span>
        <span className='flex-1 text-center'>{current}</span>
      </div>
    </div>
    )
  }

  export default GraficoInfos;