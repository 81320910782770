import React from "react";
import { iChartdata, iReportGroup } from "../../@types/reportgraphs.type";
import PageTitle from "../PageTitle/PageTitle";
import './GraphGroupPrintStyle.scss';
import ReportGraphPrint from "./components/ReportGraphPrint/ReportGraphPrint";




const ReportGroupPrint: React.FC<iReportGroup>=({data, startdate, enddate, title, state='', exibe_grafico_avg})=>{
const axis: iChartdata = {x:[], y:[], bar_colors:[], voptimum_target: 0, vstandard_target: 0, vpoor_target: 0, label_list:[], operation_name: []}
const eventtypes = {
    slip_to_slip: axis,
    pipe_move: axis,
    gross_speed: axis
    }
    const initial_bha={
    open_hole: eventtypes,
    cased: eventtypes,
    drilling: eventtypes,
    }
    const initial_casing={
    settlement: eventtypes,
    casing: eventtypes,
    belowshoedepth: eventtypes
    }
    const initial_riser={
    ascend: eventtypes,
    descend: eventtypes,
    }
const exibe_graficos = (): boolean => {
    return verifica_dados_in_range_slip_to_slip() || verifica_dados_in_range_pipe_move() || verifica_dados_in_range_gross_speed()
}
const verifica_dados_in_range_slip_to_slip = (): boolean => {
    let datas_slip_to_slip = data[state]?.['slip_to_slip']?.['x'] || []
    let datas_verificadas_slip_to_slip = datas_slip_to_slip.some((d: string) => {
        return new Date(d) >= new Date(startdate) && new Date(d) <= new Date(enddate)
    })
    return datas_verificadas_slip_to_slip
}
const verifica_dados_in_range_pipe_move = (): boolean => {
    let datas_pipe_move = data[state]?.['pipe_move']?.['x'] || []
    let datas_verificadas_pipe_move = datas_pipe_move.some((d: string) => {
        return new Date(d) >= new Date(startdate) && new Date(d) <= new Date(enddate)
    })
    return datas_verificadas_pipe_move
}
const verifica_dados_in_range_gross_speed = (): boolean => {
    let datas_gross_speed = data[state]?.['gross_speed']?.['x'] || []
    let datas_verificadas_gross_speed = datas_gross_speed.some((d: string) => {
        return new Date(d) >= new Date(startdate) && new Date(d) <= new Date(enddate)
    })
    return datas_verificadas_gross_speed
}

return(
    <div className="BreakForPrint">
    <>
    {exibe_graficos() ?(<>
    <div style={{marginBottom:'15px', width:'100%', height:'50px'}}>
    <PageTitle title={title}/>
    </div>
    {verifica_dados_in_range_slip_to_slip() ?(
    <ReportGraphPrint
        exibe_grafico_avg={exibe_grafico_avg}
        data={data}
        startdate={startdate}
        enddate={enddate} 
        bgclassname={'cinza-claro'}
        labelname={(<span>
        Slip to Slip<br></br>
        (minutes)
        </span>)}
        attributename={'slip_to_slip'}
        state={state}
        />
    ):<></>}
    {verifica_dados_in_range_pipe_move() ?(
    <ReportGraphPrint
        exibe_grafico_avg={exibe_grafico_avg}
        data={data} 
        startdate={startdate} 
        enddate={enddate} 
        bgclassname={'cinza-escuro'}
        labelname={(<span>
            Pipe Move<br></br>
            (m/h)
            </span>)}
            attributename={'pipe_move'}
            state={state}
        label_hover='Speed(m/h)'
            />
    ):<></>}
    {verifica_dados_in_range_gross_speed() ?(
    <ReportGraphPrint
        exibe_grafico_avg={exibe_grafico_avg}
        data={data} 
        startdate={startdate} 
        enddate={enddate} 
        bgclassname={'cinza-claro'}
        labelname={(<span>
            Gross Speed<br></br>
            (m/h)
            </span>)}
            attributename={'gross_speed'}
            state={state}
        label_hover='Speed(m/h)'
            />
    ):<></>}
    </>):<></>}
    </>
    </div>
)
}

export default ReportGroupPrint