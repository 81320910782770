import React from 'react';
import { Box, Button, CircularProgress, Dialog, 
  Fade, TextField, Typography 
} from '@mui/material';
import { Container } from '@mui/system';
import RzxLogo from '../../assets/images/logo1.png';
import './FormDialogStyles.scss';
import { iPocos } from '../../@types/plataforma.type';
import { updatePocoApi } from '../../services/pocos';

interface iFormDialog{
  open: boolean;
  handleClose: (platform?: iPocos)=>void;
  pocoEdit: iPocos | null;
}


const WellFormDialog: React.FC<iFormDialog> = ({open, handleClose, pocoEdit}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [client, setClient] = React.useState<String>('');
    const [latitude, setLatitude] = React.useState<Number>(0);
    const [longitude, setLongitude] = React.useState<Number>(0);
    const [country, setCountry] = React.useState<string>('');
    

  const handleClientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setClient(value);
  }

  const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLatitude(Number(value));
  }

  const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLongitude(Number(value));
  }

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCountry(value);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const dados: iPocos = {
      well_id: pocoEdit?.well_id || 0,
      well_name: data.get('well_name')?.toString() || '',
      client: data.get('client')?.toString() || '',
      latitude: Number(data.get('latitude')) || 0,
      longitude: Number(data.get('longitude')) || 0,
      country: data.get('country')?.toString() || '',
    }
    const onResolve = (res: any) => {
      setLoading(false)
      handleClose(res.data)
    }
      updatePocoApi({...dados, id: pocoEdit?.id, plataforma:pocoEdit?.plataforma}).then(onResolve)
  }

  React.useEffect(() =>{
    if(pocoEdit){
      setClient(pocoEdit.client)
      setLatitude(pocoEdit.latitude)
      setLongitude(pocoEdit.longitude) //
      setCountry(pocoEdit.country)
    } else {
      setClient('')
      setLatitude(0)
      setLongitude(0)
      setCountry('')
    }
  }, [pocoEdit]);


  return (
    <Dialog open={open} onClose={()=>handleClose()} className="form-dialog-user">
      <Container component="main" maxWidth="sm" className="form-container">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Box className="box-img">
          <img src={RzxLogo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          Register Platform
        </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="client"
              label="Client"
              name="client"
              autoFocus
              color="primary"
              variant="filled"
              value={client}
              onChange={handleClientChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="latitude"
              label="Latitude"
              name="latitude"
              color="primary"
              variant="filled"
              value={latitude}
              type="number"
              onChange={handleLatitudeChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="longitude"
              label="Longitude"
              name="longitude"
              color="primary"
              variant="filled"
              value={longitude}
              type="number"
              onChange={handleLongitudeChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="country"
              label="Country"
              name="country"
              color="primary"
              variant="filled"
              value={country}
              onChange={handleCountryChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '300ms' : '0ms',
                  marginLeft: 10
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={15} />
              </Fade>
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  )
}

  export default WellFormDialog
