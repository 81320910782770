import { CircularProgress } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { iOperacao } from '../../@types/operacao.type';
import './OperacaoStyle.scss';

interface iCellData{
  section_operation: string,
  planned_duration:string
  current_duration: string,
  start_estimated: string,
  end_estimated: string,
  delta: string,
  status: string,
  tnpi: string
}

const Tabelas: React.FC<{operacoes: iOperacao[], loading: boolean, exibir_coluna_tnpi?: boolean}> = ({operacoes, loading, exibir_coluna_tnpi=false}) => {

  const getHeaderColor = (data: iCellData[], index: number, rotated: boolean = false) => {
    // verifica se fundo é verde caso seja done ou current e primeiro da tabela ou lateral rotacionada
    if(index > 0 && !rotated){
      return '';
    }
    const hasDoneOrCurrent = data.some(item => item.status === 'done' || item.status === 'current');
    if(!hasDoneOrCurrent) return ''
    return 'text-destacado bg-verde tr-text-destacado';
  }

  const getTextoDestacado = (item: iCellData) => {
    // verifica se texto deve ser negrito caso seja done ou current
    let classe = ''
    if (item.status === 'scheduled') {
      classe = 'text-destacado';
    }
    if (item.status === 'current') {
      classe = 'text-destacado destaque';
    }
    return classe;
  }
    

  const getTextColor = (delta: string) => {
    if (delta?.includes("-") && !delta?.includes("---")) {
      return "text-success";
    } else if(delta?.includes("+")) {
      return "text-danger";
    } else {
      return "text-secondary";
    }
  }
  
  return (
    <div className='infos-tabelas flex-1 bg-cinza mr-1 p-relative'>
      {loading?
      <div style={{position: "absolute", right: 5, top: 5}}><CircularProgress size="15px" /></div>: <></>}
      <table className='table-font tabela w-100' cellSpacing="0">
        <thead className='tabela-theader'>
          <tr className='text-white'>
            <th className='rotate tr-rotate'> <span>Planned</span></th>
            <th>Section/Operation</th>
            <th>Planned Duration</th>
            <th>Current</th>
            <th>Start (estimated)</th>
            <th>End (estimated)</th>
            <th>Delta</th>
            {exibir_coluna_tnpi?
            <th>TNPI</th>
            :<></>}
          </tr>
        </thead>
        {operacoes.map((item: iOperacao, index) => (
          <tbody className="text-cinza-escuro" key={index}>
            <tr className='theader-texto-cinza'>
              <td></td>
              <th>{item.section_name}</th>
              <th>{item.planned_duration}</th>
              <th>{item.current}</th>
              <th>{item.estimated_start}</th>
              <th>{item.estimated_end}</th>
              <th className={getTextColor(item.delta)}>{item.delta}</th>
              {exibir_coluna_tnpi?
              <th className={getTextColor(item.tnpi)}>{item.tnpi}</th>
              :<></>}
            </tr>
              <tr className="section-label"><td className={`rotate`} rowSpan={item.operations?.length +2}> <span>{item.section_label}</span> </td></tr>
            {item.operations?.map((op: any, indexOp: number) => 
              (
                <tr className={getTextoDestacado(op)} key={indexOp}>
                  {/* primeira celula é rotacionada 90º com o section_label */}
                  {/* {indexOp===0 ? <td className={`rotate`} rowSpan={item.operations?.length +2}> <span>{item.section_label}</span> </td> : null} */}
                  <td>{op.name}</td>
                  <td>{op.planned_duration}</td>
                  <td>{op.current}</td>
                  <td>{op.estimated_start}</td>
                  <td>{op.estimated_end}</td>
                  <td className={getTextColor(op.delta)}>{op.delta}</td>
                  {exibir_coluna_tnpi?
                  <td className={getTextColor(op.tnpi)}>{op.tnpi}</td>
                  :<></>}
                </tr>
              )
            )}
          </tbody>
        ))}

      </table>
    </div>
  );
};

export default connect((state: {operacoes: any[], loading: {operacao: boolean}})=>(
  {
  operacoes: Array.isArray(state.operacoes) ? state.operacoes : [],
  loading: state.loading.operacao,
  }
  ))(Tabelas);