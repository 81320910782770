import React from "react";
import { iLessonLearned } from "../../@types/efficiency.type";
import './LessonLearnedTableStyle.scss';
import EfficiencyTable from "../EfficiencyTable/EfficiencyTable";

const LessonLearnedTable: React.FC<{dados: iLessonLearned[]}>=({dados})=>{
    return(
        <div className="lessonlearned-table" style={{width:'99%'}}>
            <table className="text-white" style={{width:'100%'}}>
                {dados?.map((lessonlearned: iLessonLearned, index: number) => 
              (<>
                <thead>
                <tr className="theader-texto-cinza">
                    <th>Category</th>
                    <th style={{width:'30%'}}>Description</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>Duration in Last 24h</th>
                </tr>
                </thead>
                <tbody>
                  <tr className="text-white bg-azul">
                    <td>{lessonlearned.category}</td>
                    <td>{lessonlearned.activity_name}</td>
                    <td>{lessonlearned.start_time}</td>
                    <td>{lessonlearned.end_time}</td>
                    <td>{lessonlearned.total_time}</td>
                    <td>{lessonlearned.total_accumulated}</td>
                    
                </tr>
                <tr className="Events"><td colSpan={7}>
                    <EfficiencyTable dados={{
                        total_npi:lessonlearned.total_tnpi,
                        total_accumulated:lessonlearned.total_accumulated,
                        efficiency_list:lessonlearned.lessonlearned_activities}}/>
                </td></tr>
                </tbody>
                </>)
                )}
            </table>
        </div>
    )
}

export default LessonLearnedTable