import { iPlataforma } from "../@types/plataforma.type";
import { api } from "./api";

export interface IPlat {
  name: string,
  db_name: string
}

export const getSelecaoPlataformasApi = () => {
  return api.get("plataformas/").then((res) => res.data);
};

export const getPlataformasApi = (page = 1, search = '') => {
  const params = { page, search };
  return api.get("plataforma/", { params }).then((res) => res.data);
};

export const createPlataformaApi = (plataforma: iPlataforma) => {
  return api.post("plataforma/", plataforma);
};

export const updatePlataformaApi = (plataforma: iPlataforma) => {
  return api.put(`plataforma/${plataforma.id}/`, plataforma);
};

export const deletePlataformaApi = (plataforma: iPlataforma) => {
  return api.delete(`plataforma/${plataforma.id}/`);
};
