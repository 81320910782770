import React from "react";
import './DepthInfoStyle.scss';

const DepthInfo: React.FC<{hour:string, rounded_depth:number}>=({hour,rounded_depth})=>{
    return(
        <div className="text-center">
            <h2 className="text-cinza-escuro font-bold">Hole Depth at {hour}</h2>
            
            <h3 className="text-white font-bold">{rounded_depth} m</h3>
        </div>

    )
}

export default DepthInfo