import React from "react";
import Alert, { AlertColor } from '@mui/material/Alert';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import './MetaStyle.scss';
import { connect } from "react-redux";
import { iMetaData } from "../../../@types/meta.type";
import { AlertTitle, CircularProgress } from "@mui/material";


const Meta: React.FC<{meta: {dados: iMetaData, porcentagem: number}, loading: boolean}> = ({meta, loading}) => {
  
  const getLinearGradient = (porcentagem: number) => {
    const azul = '#135571';
    const verde = '#32957D';
    const cinza = '#C4C4C4';
    const gradient = `linear-gradient(90deg, ${azul} 0%, ${verde} ${porcentagem}%, ${cinza} ${porcentagem}%, ${cinza} 100%)`;
    const css = {
      background: gradient,
    }
    return css
  }

  const getIcon = (status?: AlertColor, color?: string) => {
    switch(status){
      case 'success': return <CheckCircleIcon fontSize="inherit" color={status} style={{color: color||'inherit'}}/>;
      default: return <ReportIcon fontSize="inherit" color={status} style={{color: color||'inherit'}}/>
    }
  }

  return (
    <div className="metas d-flex bg-cinza flex-20 mr-1 px-5 d-flex flex-column text-white p-relative">
       {loading?
      <div style={{position: "absolute", right: 5, top: 4}}><CircularProgress size="10px" /></div>: <></>}
      <div className="flex-justify-between font-bold flex-1 mb-3">
        <span style={{width:'100%'}}>
          <Alert 
          severity={meta?.dados?.current_meta_label?.status || 'info'} 
          variant="filled">
            <strong>{meta?.dados?.current_meta_label?.label}</strong>
          </Alert>
        </span>
      </div>
      <div className="flex-center flex-1 mb-3 flex-column">
        <span className="font-small">current {meta?.dados.current_time} | target {meta?.dados.expected_time} </span>
      </div>
      <div className="flex-center flex-1 mb-3 flex-column">
        <div className="progresso" style={getLinearGradient(meta.porcentagem)}></div>
        <span className="font-bold font-small">{meta?.dados.current_progress} of {meta?.dados.total_progress} stands</span>
      </div>
      <div className="flex-center flex-1">
        <Alert 
          icon={getIcon(meta?.dados?.failure_report?.status || 'info')} 
          severity={meta?.dados?.failure_report?.status || 'info'} 
          variant="outlined">
            <strong>{meta?.dados?.failure_report?.label}</strong> <br></br>
            <span style={{fontSize: "13px"}}>{meta?.dados?.failure_report?.name}</span>
        </Alert>
      </div>
    </div>
    
  )
}

export default connect((state: {meta: any, loading: {meta: boolean}})=> (
  {
    meta: state.meta,
    loading: state.loading.meta
  }
  ))(Meta);