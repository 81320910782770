import React from "react";
import { Area, XAxis, YAxis, Tooltip, ComposedChart, ReferenceLine } from 'recharts';
import './GraficoStyle.scss';

type type_enum='sliptoslip'|'pipemove'|'grossspeed'
interface iGrafico {
  data: any[];
  color?: string;
  id?: string;
  referenceLine?: number;
  type_chart?: type_enum;
}

const Grafico: React.FC<iGrafico> = ({data, color, id='colorUv', referenceLine=0, type_chart}) => {
  const convertFloatToMinutes = (value:number) => {
    var min = Math.floor(value);
    var decpart = value - min;
    var sec = 1 / 60;
    decpart = sec * Math.round(decpart / sec);
    var seconds = String(Math.floor(decpart * 60));
    var minutes = String(min)
    seconds = seconds.padStart(2, '0'); 
    minutes = minutes.padStart(2, '0');
    var time = minutes + ':' + seconds;
      return time;

  }
const get_tooltip=(value: any) => {
  if (type_chart === 'grossspeed' ) {
    return value.toFixed(2) + ' m/h'
  }
  else {
    return convertFloatToMinutes(value) + ' min'
  }
}
const CustomTooltip = ({ active, payload, label }:any) => {
  if (active && payload && payload.length) {
    const data=payload[0].payload
    const textcolor=payload[0].stroke
    return (
      <div className="custom-tooltip" style={{color: textcolor, textAlign: 'left'}}>
        <p className="label" style={{margin: 1}}>{`${get_tooltip(data.x)} `}</p>
        <p className="desc" style={{margin: 1}}>{`${data.startbitdepth} m`}</p>
      </div>
    );
  }return null}

  return (
    <div className="tableas-grafico-direita d-flex flex-1">
      <ComposedChart width={100} height={100}
      layout="vertical" data={data} margin={{ top: 10, right: 0, left: -50, bottom: -20 }}>
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={color || "#8884d8"} stopOpacity={0.2}/>
            <stop offset="95%" stopColor={color || "#8884d8"} stopOpacity={0.8}/>
          </linearGradient>
        </defs>
        <XAxis type="number" dataKey="x" tick={false} domain={[0,(dataMax:number)=>(dataMax > referenceLine*3?referenceLine*3:dataMax)]} allowDataOverflow/>
        <YAxis type="number" dataKey="y" axisLine={false} />
        <Tooltip 
          separator=""
          formatter={(value: any, name:any, props:any) => {
            return[get_tooltip(value), " "]}}
          content={<CustomTooltip/>}
          labelFormatter={()=>''}
          labelStyle={{color: '#2d2d2d'}}
          wrapperStyle={{outline:'none', }}
          contentStyle={{backgroundColor: 'rgba(255, 255, 255, 0.75)', height: 16, padding: -3, visibility:'visible'}}
          position={{x: 10, y:90}}/>
        <Area type="monotone" dataKey="x" stroke={color || "#8884d8"} fillOpacity={1} fill={"url(#"+ id + ")"} />
        <ReferenceLine x={referenceLine} stroke="#fff" strokeDasharray="3 3" alwaysShow />
      </ComposedChart>
    </div>
  )
}

export default Grafico;