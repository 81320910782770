import React from 'react';
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, 
  Fade, FormControlLabel, IconButton, Switch, TextField, Typography 
} from '@mui/material';
import { Container } from '@mui/system';
import RzxLogo from '../../assets/images/logo1.png';
import { User } from '../../@types/user.type';
import { createUserApi, updateUserApi } from '../../services/usuario';
import './FormDialogStyles.scss';
import CloseIcon from '@mui/icons-material/Close';

interface iFormDialog{
  open: boolean;
  handleClose: (user?: User)=>void;
  userEdit?: User
}


const FormDialog: React.FC<iFormDialog> = ({open, handleClose, userEdit}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<String>('');
    const [email, setEmail] = React.useState<String>('');
    const [is_staff, setIsStaff] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<{email:{errors: string[], hasErrors: boolean}}>({email:{errors: [], hasErrors: false}});
    const [genericError, setGenericError] = React.useState<{detail: string, hasNotAcceptable: boolean}>({detail: '', hasNotAcceptable: false});
    const [openAlert, setOpenAlert] = React.useState(true);
  

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setName(value);
    }
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setEmail(value);
    }
    const handleIsStaffChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked
        setIsStaff(value);
    }
    const handleCloseModal = (user?: User) => {
      setGenericError({detail: '', hasNotAcceptable: false})
      handleClose(user)
    }

    const handleCatchError = (err: any) => {
      if(err.response.status === 406){
        setGenericError({detail:err.response.data.detail, hasNotAcceptable:true});
        setErrors({email:{errors:[], hasErrors:false}})
      } else if (err.response.status === 400) {
        setErrors({email:{errors:err.response.data.email, hasErrors:true}})
        setGenericError({detail:'', hasNotAcceptable:false});
      } else (
        setGenericError({detail:err.response.data.detail, hasNotAcceptable:true})
      )
      setLoading(false)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.currentTarget);
        const dados: User = {
            name: data.get('name')?.toString() || '',
            email: data.get('email-user')?.toString() || '',
            password: data.get('password')?.toString() || null,
            is_staff: data.get('is_staff') === 'on' ? true : false 
        }
        if (!userEdit){
            createUserApi(dados).then((res) => {
                setLoading(false)
                handleCloseModal(res.data)
            }).catch(handleCatchError)
        } else {
            const password = data.get('password')?.toString() || userEdit.password
            const update = {...dados, id: userEdit.id, password}
            updateUserApi(update).then((res)=> {
                setLoading(false)
                handleCloseModal(res.data)
              }).catch(handleCatchError)
        }
    }

    React.useEffect(() =>{
        if(userEdit){
            setName(userEdit.name)
            setEmail(userEdit.email)
            setIsStaff(userEdit.is_staff)
        } else {
          setName('')
          setEmail('')
          setIsStaff(false)
        }
    }, [userEdit]);

    return (
      <Dialog open={open} onClose={()=>handleCloseModal()} className="form-dialog-user">
        <Container component="main" maxWidth="sm" className="form-container">
        <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box className="box-img">
              <img src={RzxLogo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Register User
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                color="primary"
                variant="filled"
                value={name}
                onChange={handleNameChange}
                />
              <TextField
                autoComplete='false'
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email-user"
                type="email"
                color="primary"
                variant="filled"
                value={email}
                onChange={handleEmailChange}
                helperText={errors.email.errors.join(', ')}
                error={errors.email.hasErrors}
                />
              <TextField
                autoComplete='false'
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                color="primary"
                variant="filled"
              />
              <FormControlLabel 
                label="Is Staff" 
                control={
                  <Switch 
                    name='is_staff' 
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleIsStaffChange} 
                    checked={is_staff}/>
                  } 
              />
              {
                genericError.hasNotAcceptable? 
                <Collapse in={openAlert}>
                <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>{genericError?.detail}</Alert>
                </Collapse> : <></>
              }
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? '300ms' : '0ms',
                    marginLeft: 10
                  }}
                  unmountOnExit
                >
                  <CircularProgress color="inherit" size={15} />
                </Fade>
              </Button>
            </Box>
          </Box>
        </Container>
      </Dialog>
    )
  }

  export default FormDialog
