import { iPocos } from '../@types/plataforma.type';
import {api} from './api';

export const getPocoApi = (plataforma: string | null) => {
    return api.get('poco/', {params:{plataforma}}).then(res => res.data);
  }
  
  export const createPocoApi = (poco: iPocos) => {
    return api.post('poco/', poco);
  }
  
  export const updatePocoApi = (poco: iPocos) => {
    return api.put(`poco/${poco.id}/`, poco);
  }
  
  export const deletePocoApi = (poco: iPocos) => {
    return api.delete(`poco/${poco.id}/`);
  }
  