import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Fade, ThemeProvider } from "@mui/material";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import RzxLogo from '../assets/images/logo1.png';
import LoginBg from '../assets/images/login-bg.png';
import { appTheme } from '../styles/tema';
import { login } from '../services/login';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

// copiado de https://github.com/mui/material-ui/tree/v5.9.1/docs/data/material/getting-started/templates/sign-in
const Login: React.FC = () => {

  const [cookies, setCookie] = useCookies(['xpd-user-token']);
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    const dados = {
      email: data.get('username') || '',
      password: data.get('password') || '',
    }
    login(dados.email, dados.password).then((res: any) => {
      const { token } = res.data
      setCookie('xpd-user-token', token, { path: '/' });
      setLoading(false)
      navigate('/')
    }).catch((error: any) => {
      const errs = error.response?.data?.non_field_errors || []
      setErrors(errs)
      setLoading(false)
    })
  };

  return (
    <ThemeProvider theme={appTheme} >
      <Box style={{ textAlign: "center", padding: "62px 10px 20px" }}>
        <img src={RzxLogo} alt="Logo" />
      </Box>

      <Container id='login' component="main" maxWidth="sm"
        style={{
          backdropFilter: "blur(5px)",
          color: "#fff",
          borderRadius: "11px",
          boxShadow: "0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08);",
          padding: "20px 40px 30px 40px",
          background: "rgba(6, 53, 53, 0.50)"
        }}
      >

        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Sign in to your account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              color="primary"
              variant="filled"
              style={{color: "#fff", background: "white"}}
            />
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              autoComplete="current-password"
              style={{color: "#fff", background: "white"}}
            />
            {/* Para deixar salvo */}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            {errors.map((error: string) => (
              <Typography variant="subtitle2" gutterBottom component="div" color="error">
                {error}
              </Typography>
            )
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '300ms' : '0ms',
                  marginLeft: 10
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={15} />
              </Fade>
            </Button>

            {/* Esqueceu senha e cadastrar */}
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>

        {/* Creditos no rodape */}
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

export default Login