import {api} from './api';

export const getTopDrive = (plataforma: string | null): Promise<any> => {
    return api.get('topdrive/', {params:{plataforma}}).then(res => {
        const keys = Object.keys(res.data)
        let dados = keys.map(k => { 
            const data = res.data[k] || {posicao:0};
            return {...data, posicao: data?.value|| 0}
        })
        return dados
    })
}