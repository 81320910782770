import { User } from "../@types/user.type";
import { api } from "./api";

export const createUserApi = (user: User): Promise<any> => {
  return api.post(`accounts/user/`, user);
};

export const updateUserApi = (user: User): Promise<any> => {
  return api.put(`accounts/user/${user.id}/`, user);
};

export const getUsersApi = (page = 1): Promise<any> => {
  const params = { page };
  return api.get(`accounts/user/`, { params }).then((res) => res.data);
};

export const deleteUserApi = (user: User): Promise<any> => {
  return api.delete(`accounts/user/${user.id}/`);
};
