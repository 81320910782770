import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './ReportsStyle.scss';
import { getBhaReportsApi, getCasingReportsApi, getRiserReportsApi, getTimeReportsApi, getPlannedVsReal } from '../../services/reports';
import { Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider } from '@mui/material';
import { getSelecaoPlataformasApi, IPlat } from '../../services/plataforma';
import moment from 'moment';
import ReportGroup from '../../components/GraphGroup/GraphGroup';
import ReportTimeGraph from '../../components/GraphGroup/components/ReportTimeGraph/ReportTimeGraph';
import { iChartdata, iPlannedReal, iStatebha, iStatecasing, iStateriser, StateName } from '../../@types/reportgraphs.type';
import { appTheme } from '../../styles/tema';

const Reports: React.FC = () => {
  const whitecolor = "#FFF"
  const axis: iChartdata = { x: [], y: [], bar_colors: [], voptimum_target: 0, vstandard_target: 0, vpoor_target: 0, label_list: [], operation_name: [] }
  const eventtypes = {
    slip_to_slip: axis,
    pipe_move: axis,
    gross_speed: axis
  }
  const initial_bha = {
    open_hole: eventtypes,
    cased: eventtypes,
    drilling: eventtypes,
  }
  const initial_casing = {
    settlement: eventtypes,
    casing: eventtypes,
    belowshoedepth: eventtypes
  }
  const initial_riser = {
    ascend: eventtypes,
    descend: eventtypes,
  }

  const initial_plannedReal = {
    days: [],
    planned: [],
    real: []
  }

  type ReportData = {
    title: string,
    url: string
  }


  const reportTypes = ["Bar", "Summary"]

  const initial_time = axis
  const [loading, setLoading] = React.useState(false);
  //const [metabaseReports, setMetabaseReports] = React.useState<ReportData[]>([])
  const [dataPlannedVsReal ,setDataPlannedVsReal] = React.useState<iPlannedReal>(initial_plannedReal) 
  const [databha, setDatabha] = React.useState<iStatebha>(initial_bha)
  const [datacasing, setDatacasing] = React.useState<iStatecasing>(initial_casing)
  const [datariser, setDatariser] = React.useState<iStateriser>(initial_riser)
  const [datatime, setDatatime] = React.useState<iChartdata>(initial_time)
  const [startdate, setStartdate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
  const [enddate, setEnddate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
  const [plataformalist, setPlataformalist] = React.useState<IPlat[]>([])
  const [selectedplataforma, setSelectedplataforma] = React.useState<string | null>('')
  const [selectedReportType, setSelectedReportType] = React.useState<string>(reportTypes[0])

  const getSelecao = () => {
    getSelecaoPlataformasApi().then(response => {
      setPlataformalist(response)
    })
  };

  const getReports = () => {
    setLoading(true)

    let starttime = moment(startdate).format('YYYY-MM-DD HH:mm')
    let endtime = moment(enddate).format('YYYY-MM-DD HH:mm')

    let promises = []
    if (selectedReportType == "Bar") {
      promises.push(getBhaReportsApi(selectedplataforma, starttime, endtime))
      promises.push(getCasingReportsApi(selectedplataforma, starttime, endtime))
      promises.push(getRiserReportsApi(selectedplataforma, starttime, endtime))
      promises.push(getTimeReportsApi(selectedplataforma, starttime, endtime))
    } else {
      // promises.push(getMetabaseReportsApi(selectedplataforma, starttime, endtime))
      promises.push(getPlannedVsReal(selectedplataforma, starttime, endtime))
    }

    Promise.all(promises).then((res) => {
      if (selectedReportType == "Bar") {
        setDatabha(res[0].data.bha)
        setDatacasing(res[1].data.casing)
        setDatariser(res[2].data.riser)
        setDatatime(res[3].data.time)
      } else {
        //setMetabaseReports(res[0].data.urls)
        setDataPlannedVsReal(res[0].data.plannedVsReal)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  React.useEffect(() => {
    getSelecao()
  }, [])

  const getTitle = (title: string) => {
    switch (title) {
      case "PRODUCTION_TIMES":
        return "Production times"
      case "NPT":
        return "Non productive time"
      case "ILT":
        return "Invisible lost time"
      case "HOURS_BY_TYPE":
        return "Production hours by type"
      case "PLANNED_VS_EXECUTED":
        return "Well depth"
      case "SUB_OPERATION":
        return "Sub operations"
      default:
        return ""
    }
  }

  return (
    <ThemeProvider theme={appTheme} >
      <div className="reports vh-100">
        <NavBar />
        <div className="d-flex text-white pl-3 py-1">
          XPD {'>'} {selectedplataforma} {'>'} reports

        </div>
        <div className="d-flex">
          <div className="d-flex flex-1 vh-80 flex-column">
            <div className='d-flex w-100 p-2 text-white bg-cinza' style={{ alignItems: "center", placeContent: "center" }}>

              <FormControl sx={{ minWidth: 200, m: 2 }}>
                <InputLabel id="demo-simple-select-label" sx={{ color: '#FFF' }}>Rig</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedplataforma}
                  label="Rig"
                  onChange={(e) => { setSelectedplataforma(e.target.value) }}
                  sx={{
                    svg: { color: whitecolor }
                  }}
                  style={{ color: whitecolor }}
                >
                  {plataformalist.map(plataforma => (
                    <MenuItem value={plataforma.db_name} key={plataforma.name}>{plataforma.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 200, m: 2 }}>
                <InputLabel id="reportType" sx={{ color: '#FFF' }}>Report type</InputLabel>
                <Select
                  labelId="reportType"
                  id="reportTypeSelect"
                  value={selectedReportType}
                  label="Report type"
                  onChange={(e) => { setSelectedReportType(e.target.value) }}
                  sx={{
                    svg: { color: whitecolor }
                  }}
                  style={{ color: whitecolor }}
                >
                  {reportTypes.map(reportType => (
                    <MenuItem value={reportType} key={reportType}>{reportType}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="datetime-local"
                label="Start Date"
                type="datetime-local"
                value={startdate}
                onChange={(e) => { setStartdate(e.target.value) }}
                sx={{
                  svg: { color: whitecolor },
                  input: { color: whitecolor },
                  label: { color: whitecolor },
                  m: 2
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                id="datetime-local"
                label="End Date"
                type="datetime-local"
                value={enddate}
                onChange={(e) => { setEnddate(e.target.value) }}
                sx={{
                  svg: { color: whitecolor },
                  input: { color: whitecolor },
                  label: { color: whitecolor },
                  m: 2
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button variant="contained" onClick={() => { getReports() }}>
                Generate report
              </Button>
            </div>

            {loading && (
              <CircularProgress sx={{ margin: "50px auto" }} />
            )}

            {!loading && selectedReportType == "Bar" && (
              <>
                <ReportGroup
                  data={databha}
                  startdate={startdate}
                  enddate={enddate}
                  title={'BHA - Open Hole'}
                  state={StateName.open_hole}
                />
                <ReportGroup
                  data={databha}
                  startdate={startdate}
                  enddate={enddate}
                  title={'BHA - Cased'}
                  state={StateName.cased}
                />
                <ReportGroup
                  data={databha}
                  startdate={startdate}
                  enddate={enddate}
                  title={'BHA - Drilling'}
                  state={StateName.drilling}
                />
                <ReportGroup
                  data={datacasing}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Casing- Settlement String'}
                  state={StateName.settlement}
                />
                <ReportGroup
                  data={datacasing}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Casing'}
                  state={StateName.casing}
                />
                <ReportGroup
                  data={datacasing}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Casing - Open Hole'}
                  state={StateName.belowshoedepth}
                />
                <ReportGroup
                  data={datariser}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Riser - Ascent'}
                  state={StateName.ascend}
                />
                <ReportGroup
                  data={datariser}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Riser - Descend'}
                  state={StateName.descend}
                />
                <ReportTimeGraph
                  data={datatime}
                  startdate={startdate}
                  enddate={enddate}
                  title={'Time Operations'}
                />
              </>
            )}

            {!loading && selectedReportType == "Summary" && (
              <ReportGroup
                data={dataPlannedVsReal}
                startdate={startdate}
                enddate={enddate}
                title={'Planned x Real'}
                // state={StateName.plannedReal}
            />
            )}

          {/* {!loading && selectedReportType == "Summary" && metabaseReports.length > 0 && (
              <div className="grid">
                {metabaseReports.map(report => (
                  <div>
                    <p className='title'>{getTitle(report.title)}</p>
                    <iframe className='metabase-report'
                      src={report.url}
                      frameBorder="0"
                      allowTransparency
                    />
                  </div>
                ))}
              </div>
            )} */}

          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Reports