import { api } from "./api";

export interface BriefingPlataformas {
    name: string,
    db_name: string,
    last_update?: string,
    well?: {
        name: string,
        current_holedepth: number,
        end_holedepth: number
    },
    section?: {
        name: string,
        planned_duration: string,
        current_duration: string
    },
    operation?: {
        name: string,
        current_duration: string,
        planned_duration: string
    }
}

export const getBriefingPlataformasApi = () => {
    return api.get("briefing_plataformas/")
};

