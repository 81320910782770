import React from "react";
import Plot from "react-plotly.js";
import './GraphPiePrintStyle.scss';
import { iPieChart } from "../../../@types/efficiency.type";


const GraphPiePrint: React.FC<iPieChart>=({values, labels, text, title})=>{
    return(
        <div className="PieGraphPrint BreakForPrint">
            <Plot
                data={[
                {
                    type: 'pie',
                    values: values,
                    labels: labels,
                    text: text,
                    texttemplate: "%{label}: %{percent}<br>" +
                                  "%{text}",
                    textposition: "outside",
                    automargin: true

                },
                ]}
                layout={ {
                    height: 450,
                    width: 600,
                    title: title,
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    font: {
                        size: 11,
                        color: '#707070'},
                    legend: {
                        orientation:'h',
                        traceorder: 'normal',
                        font:{
                        size:12,
                            },
                        x: -0.5,
                        y: -0.6
                            },
                    margin: {"t": 120, "b": 70, "l": 0, "r": 0},  
                        } }
                config={{displaylogo: false}}
            />
        </div>
    )
}

export default GraphPiePrint