import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { appTheme } from "../../styles/tema";
import { deleteUserApi, getUsersApi } from "../../services/usuario";
import { User } from "../../@types/user.type";
import FormDialog from "../../components/UserCreateFormDialog/FormDialog";
import "./UsuariosStyles.scss";
import BoxLogo from "../../components/BoxLogo/BoxLogo";
import Pagination from "@mui/material/Pagination";
import { countPages } from "../../utils/functions";
import LinearProgress from "@mui/material/LinearProgress";

const Usuarios: React.FC = () => {
  /**usestate de usuarios  */
  const [userEdit, setUserEdit] = React.useState<User | undefined>(undefined);
  const [userDelete, setUserDelete] = React.useState<User | undefined>(
    undefined,
  );
  const [users, setUsers] = React.useState<Array<User>>([]);
  const [open, setOpen] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] =
    React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);

  const history = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  /** Ao fechar verifica se esta editando ou criando um novo
   * e adiciona na lista de usuario a alteração ou o novo usuario
   */
  const handleClose = (user?: User) => {
    setOpen(false);
    if (user && userEdit) {
      const newList = users.map((u) => (u.id === userEdit.id ? user : u));
      setUsers(newList);
      setUserEdit(undefined);
    } else if (user) {
      setUsers(users.concat(user));
    } else {
      getUsers();
    }
    setUserEdit(undefined);
  };

  const handleClickEditButton = (user: User) => {
    setUserEdit(user);
    setOpen(true);
  };

  const handleDeleteButton = (user: User) => {
    setUserDelete(user);
    setOpenDeleteDialog(true);
  };

  /** Ao confirar no dialog, envia para api o usuario e dando ok, remove
   * o usuario da lista na tela
   */
  const handleConfirmDelete = (user?: User) => {
    setOpenDeleteDialog(false);
    if (user) {
      const newList = users.filter((u) => u.id !== user.id);
      deleteUserApi(user).then(() => {
        setUsers(newList);
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: any }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }));

  const getUsers = () => {
    setLoading(true);
    getUsersApi(page)
      .then((res) => {
        setCount(countPages(res.count));
        setUsers(res.results);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, [page]);

  return (
    <ThemeProvider theme={appTheme}>
      <Box className="usuarios-page">
        <BoxLogo />
        <Box sx={{ width: "100%", marginBottom: 1 }}>
          <Button
            variant="contained"
            onClick={() => history(-1)}
            sx={{ float: "left" }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{ float: "right" }}
          >
            New
          </Button>
        </Box>
        <Table size="small" className="bg-branco">
          <TableHead style={{ backgroundColor: "rgba(220, 220, 220, 0.5)" }}>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Is Staff</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              users.map((user: User) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.is_staff ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleClickEditButton(user)}>
                      <BorderColorIcon></BorderColorIcon>
                    </Button>
                    <Button onClick={() => handleDeleteButton(user)}>
                      <DeleteIcon color="error"></DeleteIcon>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  {" "}
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination count={count} page={page} onChange={handleChange} />
      </Box>
      {/* Dialog confirm delete user */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete the user {userDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={() => handleConfirmDelete(userDelete)}
            autoFocus
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialog confirm delete user */}
      <FormDialog open={open} handleClose={handleClose} userEdit={userEdit} />
    </ThemeProvider>
  );
};

export default Usuarios;
